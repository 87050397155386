// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'http://orders.ui.co',
    apiUrl: 'http://orders.ui.co/api',
    mobile: false,
    franchise: 'Hello Tomato',
    serviceConfig: {
        apiUrl: 'http://orders.ui.co/api',
        baseUrl: 'http://orders.ui.co',
        proxyUrl: 'http://orders.ui.co/api',
        mobile: false,
    },
    keycloakConfig: {
        config: {
            realm: 'order.me.co',
            clientId: 'order.me.co',
            credentials: {
                secret: '7c3e3d60-1c10-4a5e-9cb5-f98a601fbbc8',
            },
            url: 'http://orders.ui.co/auth',
        },
        initOptions: {
            // onLoad: 'login-required',
            onLoad: 'check-sso',
            // checkLoginIframe: false,
        },
        enableBearerInterceptor: true,
        // loadUserProfileAtStartUp: true,
        bearerExcludedUrls: [
            '/assets'
        ],
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
