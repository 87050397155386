import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicKeycloakAuthModule } from '@cmotion/ionic-keycloak-auth';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { AngularKeycloakModule } from '@fingerprintsoft/angular-keycloak';
import { SharedServicesModule } from 'shared-services';
import { AngularHalModule } from '@fingerprintsoft/angular-spring-hal';
import { KeycloakAngularModule } from 'keycloak-angular';

let keycloak;
const ionicKeycloak = [
    IonicKeycloakAuthModule.forRoot({
        jwtModuleOptions: {
            // Not optional: As absolute necessity, there should be getters and setters for the token.
            // These can be async.
            getToken: () => JSON.parse(localStorage.getItem('token')),
            setToken: (value) => localStorage.setItem('token', value ? JSON.stringify(value) : null),
            // These configuration are the same as of Jwt Library. It is to whitelist any domains that you want to make requests to.
            whitelistedDomains: ["example.com"],
            // Here the inverse for blacklisted Routes
            blacklistedRoutes: ["example.com/examplebadroute/"],
        },
        deepLinksConfig: {
            // Not optional: Here is the reference of the scheme, which was used for the deeplinks before.
            deepLinkingScheme: 'ordermeco',
        },
        keycloakConfig: {
            // Not optional: You need to define where the configuration for the keycloak is to be found. This can also be async.
            jsonConfig: () => environment.keycloakConfig,
        },
    }),
];

//TODO: some kind of AngularKeyCloak module.
const angularKeycloak = [
    AngularKeycloakModule.forRoot({
        keyCloakConfiguration: {
            // @ts-ignore
            jsonConfig: () => environment.keycloakConfig,
        },
    }),
];

if (environment.mobile) {
    keycloak = ionicKeycloak;
} else {
    keycloak = angularKeycloak;
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        ...keycloak,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        KeycloakAngularModule,
        AngularHalModule.forRoot(),
        SharedServicesModule.forRoot({
            serviceOptions: {
                jsonConfig: () => environment.serviceConfig,
            },
        }),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
