import { Component, HostBinding, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Plugins } from '@capacitor/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TenantService } from 'shared-services';

const {App} = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private zone: NgZone,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private tenantService: TenantService,
    ) {
        this.initializeApp();
    }

    @HostBinding("class.app") public appTheme: boolean;

    initializeApp() {
        App.addListener('appUrlOpen', (data: any) => {
            this.zone.run(() => {
                // Example url: http://app.orders.ui.co/tabs/orders
                // slug = /tabs/orders
                const slug = data.url.split(".co").pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
                // If no match, do nothing - let regular routing
                // logic take over
            });
        });
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        this.enableThemes();
    }

    enableThemes() {
        this.appTheme = this.tenantService.getTenant() === 'app';
    }
}
